export default class Chord {

  get name() {
    return 'chord'
  }

  get schema() {
    return {
      content: 'text*',
      toDOM: () => ['ch', 0],
      parseDOM: [{
        tag: `ch`,
      }],
    }
  }
}