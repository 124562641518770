export default class Doc {
  constructor() { }

  get name() {
    return 'doc'
  }

  get schema() {
    return {
      content: 'part+',
    }
  }
}