import { COMBINED_SCALE, MAJOR_SCALE, CHORDS_DEF } from './index';
import { KeySignatures } from './KeySignatures';
import { Chord } from './Transposer';
import { useTranspose } from '@/hooks/use-transpose';

const { convertScaleIndex } = useTranspose();

// then you add the notes from the chord to your array
// this is based on the interval signature of each chord.
// By adding definitions to CHORDS_DEF, you can handle as
// many chords as you want, as long as they have a unique regexp signature
function addNotesFromChord(notesArray, noteIndex, chordInterval) {

    if (notesArray.indexOf(COMBINED_SCALE[convertScaleIndex(noteIndex)]) == -1) {
        notesArray.push(COMBINED_SCALE[convertScaleIndex(noteIndex)])
    }
    chordInterval.forEach(function (int) {
        if (notesArray.indexOf(COMBINED_SCALE[convertScaleIndex(noteIndex + int)]) == -1) {
            notesArray.push(COMBINED_SCALE[convertScaleIndex(noteIndex + int)])
        }
    });
}

// once your array is populated you check each scale
// and match the notes in your array to each,
// giving scores depending on the number of matches.
// This one doesn't penalize for notes in the array that are
// not in the scale, this could maybe improve a bit.
// Also there's no weight, no a note appearing only once
// will have the same weight as a note that is recurrent. 
// This could easily be tweaked to get more accuracy.
function compareScalesAndNotes(notesArray, originalSingleChordIndex = null) {
    let bestGuess = [{
        score: 0
    }];
    COMBINED_SCALE.forEach(function (note, i) {
        var score = 0;
        score += notesArray.indexOf(note) != -1 ? 1 : 0;
        score += i === originalSingleChordIndex ? 1 : 0;
        MAJOR_SCALE.forEach(function (noteInt) {
            score += notesArray.indexOf(COMBINED_SCALE[convertScaleIndex(noteInt + i)]) != -1 ? 1 : 0;
        });

        // Always keep the highest score (or scores)
        if (bestGuess[0].score < score) {
            bestGuess = [{
                score: score,
                key: note
            }];
        } else if (bestGuess[0].score == score) {
            bestGuess.push({
                score: score,
                key: note
            })
        }
    })
    return bestGuess;
}

function guessKey(chordsArray) {
    const notesArray = [];

    chordsArray.forEach(function (chord) {
        const chordIndex = KeySignatures.valueOf(Chord.getRootAndMinor(chord)).rank;
        addNotesFromChord(notesArray, chordIndex, CHORDS_DEF.major);
    });

    const guesses = compareScalesAndNotes(notesArray,
        chordsArray.length == 1 ?
            KeySignatures.valueOf(Chord.getRootAndMinor(chordsArray[0])).rank :
            null);
    return guesses;
}

function getBestGuess(guesses) {
    if (!guesses.undefined && guesses.length) {
        const map = guesses.reduce(function (map, curr) {
            var key = curr.key;
            var score = +curr.score;
            map[key] = (map[key] || 0) + score;
            return map;
        }, {});

        return Object.keys(map).reduce((a, b) => map[a] > map[b] ? a : b);
    } else {
        return null;
    }
}

export { guessKey, getBestGuess };