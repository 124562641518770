
import { defineComponent } from "vue";

import { Schema, DOMParser, DOMSerializer } from "prosemirror-model";
import { EditorState, TextSelection, Plugin, PluginKey } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { undo, redo, history } from "prosemirror-history";
import { keymap } from "prosemirror-keymap";
import { baseKeymap } from "prosemirror-commands";
import placeholder from "@/helpers/editor/plugins/Placeholder";

import {
  Doc,
  Paragraph,
  Text,
  SongPart,
  SongPartName,
  Chord,
} from "@/helpers/editor/extensions";

let nodes: any, schema: any, view: any;
const extensions = [
  new Doc(),
  new Text(),
  new Paragraph(),
  new SongPart(),
  new SongPartName(),
  new Chord(),
];
const parseOptions: any = { preserveWhitespace: "full" };
const emptyDocument = {
  type: "doc",
  content: [
    {
      type: "paragraph",
    },
  ],
};

export default defineComponent({
  props: {
    content: {
      type: String,
      default: null,
    },
    placeholder: String
  },
  watch: {
    content() {
      const { doc, tr } = view.state;
      const document = this.createDocument(this.content);

      let cursorPosition = tr.curSelection.anchor || 1;
      cursorPosition = cursorPosition <= 2 ? 2 : cursorPosition;

      const selection = TextSelection.create(doc, 0, doc.content.size);
      const transaction = tr
        .setSelection(selection)
        .replaceSelectionWith(document, false)
        .setMeta("preventUpdate", true);

      view.dispatch(transaction);
      this.setSelection(cursorPosition, cursorPosition);
    },
  },
  mounted() {
    nodes = this.createNodes();
    schema = this.createSchema();
    view = this.createView();
  },
  methods: {
    createDocument(content: any): any {
      if (content === null) {
        return schema.nodeFromJSON(emptyDocument);
      }

      if (typeof content === "string") {
        const htmlString = `<div>${content}</div>`;
        const parser = new window.DOMParser();
        const element = parser.parseFromString(htmlString, "text/html").body
          .firstElementChild;
        return DOMParser.fromSchema(schema).parse(
          element as Node,
          parseOptions
        );
      }

      return false;
    },
    createNodes() {
      return extensions.reduce(
        (nodes, { name, schema }) => ({
          ...nodes,
          [name]: schema,
        }),
        {}
      );
    },
    createSchema() {
      return new Schema({ nodes: nodes });
    },
    createView() {
      return new EditorView(this.$refs.editor as Node, {
        state: this.createState(),
        dispatchTransaction: this.dispatchTransaction,
      });
    },
    createState(): any {
      return EditorState.create({
        schema,
        doc: this.createDocument(this.content),
        plugins: [
          history(),
          keymap({ "Mod-z": undo, "Mod-y": redo }),
          keymap(baseKeymap),
          new Plugin({
            key: new PluginKey('editable'),
            props: {
              editable: () => true
            }
          }),
          placeholder(this.placeholder)
        ],
      });
    },
    dispatchTransaction(transaction: any) {
      const newState = view.state.apply(transaction);
      view.updateState(newState);

      if (!transaction.docChanged || transaction.getMeta("preventUpdate")) {
        return;
      }

      this.emitUpdate(transaction);
    },
    emitUpdate(transaction: any) {
      this.$emit("update", this.getHTML());
    },
    getHTML() {
      const div = document.createElement("div");
      const fragment = DOMSerializer.fromSchema(schema).serializeFragment(
        view.state.doc.content
      );

      div.appendChild(fragment);
      return div.innerHTML;
    },
    setSelection(from = 0, to = 0) {
      const { doc, tr } = view.state;
      const resolvedFrom = this.minMax(from, 0, doc.content.size);
      const resolvedEnd = this.minMax(to, 0, doc.content.size);
      const selection = TextSelection.create(doc, resolvedFrom, resolvedEnd);
      const transaction = tr.setSelection(selection);

      view.dispatch(transaction);
    },
    minMax(value: any = 0, min = 0, max = 0) {
      return Math.min(Math.max(parseInt(value, 10), min), max);
    },
  },
});
