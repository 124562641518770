export default class Paragraph {
    constructor() { }

    get name() {
        return 'paragraph'
    }

    get schema() {
        return {
            content: 'text*',
            defining: true,
            parseDOM: [{
                tag: 'p',
            }],
            toDOM: () => ['p', 0],
        }
    }

}