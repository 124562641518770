export default class Text {
    constructor() { }

    get name() {
        return 'text'
    }

    get schema() {
        return {
            inline: true
        }
    }
}