import { Plugin } from 'prosemirror-state';
import { Decoration, DecorationSet } from 'prosemirror-view';

const options = {
  emptyEditorClass: 'is-editor-empty',
  emptyNodeClass: 'is-empty',
  emptyNodeText: 'Write something …',
  showOnlyWhenEditable: true,
  showOnlyCurrent: true,
}

export default (text) => {
  if (text) { options.emptyNodeText = text; }

  return new Plugin({
    props: {
      decorations: ({ doc, plugins, selection }) => {
        const editablePlugin = plugins.find(plugin => plugin.key.startsWith('editable$'));
        const editable = editablePlugin.props.editable();
        const active = editable || !options.showOnlyWhenEditable;
        const { anchor } = selection;
        const decorations = [];
        const isEditorEmpty = doc.textContent.length === 0;

        if (!active) {
          return false;
        }

        doc.descendants((node, pos) => {
          const hasAnchor = anchor >= pos && anchor <= (pos + node.nodeSize);
          const isNodeEmpty = node.content.size === 2;

          if ((hasAnchor || !options.showOnlyCurrent) && isNodeEmpty) {
            const classes = [options.emptyNodeClass];

            if (isEditorEmpty) {
              classes.push(options.emptyEditorClass);
            }

            const decoration = Decoration.node(pos, pos + node.nodeSize, {
              class: classes.join(' '),
              'data-empty-text': typeof options.emptyNodeText === 'function'
                ? options.emptyNodeText(node)
                : options.emptyNodeText,
            })
            decorations.push(decoration);
          }

          return false;
        });

        return DecorationSet.create(doc, decorations);
      }
    }
  })
}
