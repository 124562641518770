export default class SongPart {

  get name() {
    return 'part'
  }

  get schema() {
    return {
      content: 'partname (paragraph | chord)*',
      defining: true,
      toDOM: () => ['part', 0],
      parseDOM: [{
        tag: `part`,
      }],
    }
  }
}