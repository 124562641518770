<template>
  <template v-if="song">
    <Topbar :hideMenu="true" />
    <h1 v-if="!edit">Nummer toevoegen</h1>
    <h1 v-if="edit">Nummer wijzigen</h1>

    <form @submit.prevent="songSubmit" class="form mt-xs-1 mt-sm-15">
      <div class="form-field">
        <label for="field1">Titel</label>
        <input id="field1" type="text" v-model.trim="song.title" required />
      </div>
      <div class="form-field">
        <label for="field2">Artiest</label>
        <input id="field2" type="text" v-model.trim="song.artist" required />
      </div>
      <div class="form-field">
        <label for="field3">Tempo (bpm)</label>
        <input
          id="field3"
          type="number"
          min="30"
          max="240"
          v-model.number="song.bpm"
          placeholder="30 - 240"
        />
      </div>
      <div class="form-field">
        <label for="field4">Maatsoort</label>
        <select id="field4" v-model="song.timeSignature">
          <option v-for="signature in TimeSignature" :key="signature">
            {{ signature }}
          </option>
        </select>
      </div>
      <div class="form-field">
        <label for="field5">Toonhoogte</label>
        <select id="field5" :value="song.key" @change="transposeSong">
          <option
            v-for="key in COMBINED_SCALE"
            :key="key"
            :disabled="!song.key"
          >
            {{ key }}
          </option>
        </select>
      </div>

      <SongInputEditor
        v-if="song.short_id"
        :song="song"
        @update="setSongContentAndKey"
      />

      <p
        v-if="
          formError && (!song.title || !song.artist || !song.key || !song.input)
        "
        class="mt-xs-1"
      >
        Je kunt nog niet opslaan, er ontbreken nog waardes voor:
      </p>
      <ul v-if="formError">
        <li v-if="!song.title">Titel</li>
        <li v-if="!song.artist">Artiest</li>
        <li v-if="!song.key">Toonhoogte (voeg akkoorden toe)</li>
        <li v-if="!song.input">Inhoud van het nummer</li>
      </ul>

      <div class="form-navigation">
        <input class="button mr-xs-025 mr-sm-1" type="submit" value="Opslaan" />
        <button type="button" class="button-secondary" @click="songCancel">
          Annuleren
        </button>
      </div>
    </form>
  </template>
</template>

<script lang="ts">
import XRegExp from "xregexp";
import { defineComponent, computed, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { useSongs } from "@/hooks/use-songs";
import { useTranspose } from "@/hooks/use-transpose";
import { Song, TimeSignature } from "@/types/song";
import { COMBINED_SCALE } from "@/helpers/transpose";
import Transposer from "@/helpers/transpose/Transposer";
import Topbar from "@/components/Topbar.vue";
import SongInputEditor from "@/components/song/SongInputEditor.vue";

export default defineComponent({
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();
    const { updateTransposeIfDefault } = useTranspose();
    const { getSong, editSong, addSong, getSongUrl } = useSongs();

    const song: any = computed(() => {
      if (props.edit) {
        return reactive<Song>(
          Object.assign({}, getSong(route.params.id as string))
        );
      } else {
        return reactive<Song>({
          _id: "",
          short_id: Math.random().toString(36).substr(2, 11),
          title: "",
          artist: "",
          key: "",
          bpm: null,
          timeSignature: TimeSignature.FourFour,
          input: "",
          parts: [],
          recentlyAdded: true
        });
      }
    });

    const setSongContentAndKey = (data: any) => {
      song.value.input = data.content;
      song.value.key = data.key;
    };

    const transposeSong = (event: any) => {
      const newKey = event.target.value;
      if (song.value.input && song.value.key && song.value.key != newKey) {
        const result = XRegExp.replaceEach(song.value.input, [
          [
            XRegExp(`<ch>(?<chords>.+?)</ch>`, "g"),
            (match) => {
              return (
                "<ch>" +
                Transposer.transpose(match.chords)
                  .fromKey(song.value.key)
                  .toKey(newKey)
                  .toString() +
                "</ch>"
              );
            },
          ],
        ]);

        song.value.input = result;
        song.value.key = newKey;
      }
    };

    const formError = ref(false);

    const songSubmit = async () => {
      if (
        song.value.title &&
        song.value.artist &&
        song.value.key &&
        song.value.input
      ) {
        const oldSong = getSong(route.params.id as string);

        // todo vervang lege Ps in PARTS

        const res = props.edit
          ? await editSong(song.value)
          : await addSong(song.value);
          
        if (res) {
          if (props.edit) {
            toast(`"${song.value.title}" is succesvol gewijzigd.`);
            updateTransposeIfDefault(oldSong, song.value);
          } else {
            toast(`"${song.value.title}" is toegevoegd.`);
          }

          router.go(-1);
        } else {
          toast("Er is iets misgegaan, probeer later opnieuw.");
        }
      } else {
        formError.value = true;
      }
    };

    const songCancel = () => {
      router.go(-1);
    };

    return {
      song,
      setSongContentAndKey,
      TimeSignature,
      COMBINED_SCALE,
      transposeSong,
      songSubmit,
      songCancel,
      formError,
    };
  },
  components: {
    Topbar,
    SongInputEditor,
  },
});
</script>