export default class SongPartName {

  get name() {
    return 'partname'
  }

  get schema() {
    return {
      content: 'text*',
      defining: true,
      toDOM: () => ['part-name', 0],
      parseDOM: [{
        tag: `part-name`,
      }],
    }
  }
}