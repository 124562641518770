<template>
  <div class="form-field">
    <TextEditor
      v-if="song"
      class="song-rawinput"
      :content="content"
      :placeholder="placeholder"
      @update="convertInputToSong"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from "vue";
import { Song } from "@/types/song";
import { tokenize, Chord } from "@/helpers/transpose/Transposer";
import { guessKey, getBestGuess } from "@/helpers/transpose/GuessKey";
import TextEditor from "../TextEditor.vue";

export default defineComponent({
  components: { TextEditor },
  props: {
    song: {
      type: Object as PropType<Song>,
      required: true,
    },
  },
  setup(props) {
    const song: Song = props.song;
    const content = ref(song.input.replace(/<p>\s*?<\/p>/g, ""));
    const placeholder =
      "[Verse]\nEm          C\nLorem ipsum dolor sit amet,\n   G                   Dsus4\nconsectetur adipiscing elit\n\n[Chorus]\n...";

    return { content, placeholder };
  },
  watch: {
    "song.key": function (newVal, oldVal) {
      if (newVal) {
        this.convertInputToSong(this.song.input);
      }
    },
  },
  methods: {
    convertInputToSong(input: any) {
      if (!input) {
        return;
      }
      input = input.replaceAll("[ch]", "").replaceAll("[/ch]", "");
      input = input.replaceAll("<sub>", "").replaceAll("</sub>", "");
      input = input.replaceAll("&nbsp;", " ");
      input = input.replace(/<p>\s*?<\/p>(<p>\s*?<\/p>)/g, "</part><part>$1");
      input = input.replace(
        /(<part><part-name>\s*?<\/part-name><\/part>){2,}/g,
        "$1"
      );
      input = this.addChords(input);

      if (this.content != input) {
        this.content = input;

        this.$emit("update", {
          content: this.content,
          key: this.guessSongKey(),
        });
      }
    }, 
    guessSongKey() {
      var probableKeys: any[] = [];
      var results = Array.from(
        this.content.matchAll(/<ch>(?<chords>.+?)<\/ch>/g)
      );

      results.forEach((result: any) => {
        var chords = tokenize(result.groups.chords)[0].filter(
          (token) => token instanceof Chord
        );
        probableKeys = probableKeys.concat(guessKey(chords));
      });

      return getBestGuess(probableKeys);
    },
    addChords(input: string) {
      // Check if text contains chords
      return input.replace(
        /(?:<p>(.+?)<\/p>|<ch>(.+?)<\/ch>)/g,
        function (match, p1, p2) {
          let token = p1 || p2;

          if (token) {
            let chords = tokenize(token)[0].filter((x) => x.root != null);
            if (chords.length) {
              return `<ch>${token}</ch>`;
            } else {
              return `<p>${token}</p>`;
            }
          }

          return match;
        }
      );
    },
  },
});
</script>

<style lang="scss">
.song-rawinput {
  margin-top: 50px;
  margin-bottom: 50px;
  width: 100%;
  overflow-x: auto;

  .ProseMirror {
    min-height: 225px;
    outline: none;

    part {
      display: block;
      padding-bottom: 60px;

      &.is-editor-empty:first-child::before {
        content: attr(data-empty-text);
        float: left;
        pointer-events: none;
        height: 0;
        color: rgba(var(--white-500-rgb), 0.7);
      }

      &:not(.is-editor-empty) part-name {
        padding: 2px 0 4px;
        margin-bottom: 5px;
        border-top: 1px solid var(--white-500);
        border-bottom: 1px solid var(--white-500);
      }

      &:not(:first-child).is-empty part-name::before {
        @include font-weight-default();
        content: "[Name]";
        display: block;
        position: absolute;
        top: 2px;
        left: 0;
        color: var(--white-500);
        opacity: 0.7;
      }

      part-name {
        position: relative;
        display: block;
        font-weight: bold;
      }

      ch {
        display: block;
        font-weight: bold;
        white-space: pre;
      }

      p {
        white-space: pre;
        margin-bottom: 0;
      }
    }
  }
}
</style>